import React from "react";
import styles from "./styles";
import { makeStyles } from "@material-ui/styles";
import Link from "../../Link";
import ReactHtmlParser from "react-html-parser";
import Img from "gatsby-image";
import { useStaticQuery, graphql } from "gatsby";

import { CgbTabSeparatorBlock } from "../../blocks/CgbTabSeparatorBlock";
import { CoreHeadingBlock } from "../../blocks/CoreHeadingBlock";
import { CgbImageLinkRowBlock } from "../../blocks/CgbImageLinkRowBlock";

const useStyles = makeStyles((theme) => styles(theme));

function Logo(props) {
  if (!props.provider.courseProviderInfo.logo) return null;

  return (
    <Img
      className="brand-logo"
      fluid={
        props.provider.courseProviderInfo.logo.localFile.childImageSharp.fluid
      }
    />
  );
}

export default function CourseRelatedCourses(props) {
  const classes = useStyles();

  const now = new Date();

  const data = useStaticQuery(graphql`
    query coursePageLinks {
      wp {
        themeOptions {
          ThemeOptions {
            coursePageLinks {
              linkImage {
                localFile {
                  childImageSharp {
                    fluid(quality: 80, maxWidth: 1660) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
              buttonText
              linkText
              url
            }
          }
        }
      }
    }
  `);

  let linkBlocks = [];

  data.wp.themeOptions.ThemeOptions.coursePageLinks.map((link) => {
    linkBlocks.push({
      attributes: {
        className: "",
        linkURL: link.url,
        localFile: link.linkImage.localFile,
        subText: link.buttonText,
        text: link.linkText,
      },
      innerBlocks: [],
      name: "cgb/image-link",
      __typename: "WpCgbImageLinkBlock",
    });
    return null;
  });

  return (
    <div className={`course-related-courses ${classes.courseRelated}`}>
      <CoreHeadingBlock
        attributes={{
          align: "",
          anchor: "",
          className: "",
          content: "Related Courses",
          level: 2,
          textColor: "primary",
          backgroundColor: "",
          __typename: "WpCoreHeadingBlockAttributes",
        }}
        innerBlocks={[]}
      />
      {props.courses !== null &&
          <>
            <div className="course-titles">
                <div className="provider">Provider</div>
                <div className="title">Course Title</div>
                <div className="location">Location</div>
                <div className="category">Course Category</div>
                <div className="length">Length</div>
                <div className="dates">Date</div>
            </div>
            <div className="course-listing">
                {props.courses.map(course => {
                    let hasOnline = false;
                    let places = '';
                    if (course.courses.places) {
                        hasOnline = course.courses.places.filter(place => place === 'Online').length > 0;
                        if (course.courses.places.length > 4) {
                          places = <span>Multiple Course Locations Available</span>
                        } else {
                          places = course.courses.places.map(place => <span key={course.id + place.title}>{place.title}</span>)
                        }
                    }

                    const hasDates = props.courseDates.edges
                        .map(node => node.node)
                        .filter(date => date.courseData.course?.databaseId === course.databaseId)
                        .filter(date => {
                            const { startDate } = date.courseData;
                            if (!startDate) return false
                            const startDateEpoch = new Date(startDate.split('/').reverse().join('-')).getTime();
                            const nowEpoch = now.getTime();

                            if (nowEpoch > startDateEpoch) return false;

                            return date;
                        })
                        .length;

              return (
                <div className="course-item">
                  <div className="provider">
                    <span className="title">Provider </span>
                    <Logo provider={course.courses.provider} />
                  </div>
                  <div className="title">
                    <span className="title">Course Title</span>
                    <Link to={course.uri}>{ReactHtmlParser(course.title)}</Link>
                  </div>
                  <div className="location">
                    <span className="title">Location</span>
                    {places}
                  </div>
                  <div className="category">
                    <span className="title">Course Category</span>
                    {course.courseCategories !== null && <span>{course.courseCategories.nodes[0].name}</span>}
                  </div>
                  <div className="length">
                    <span className="title">Length</span>
                    {course.courses.length !== null && <span>{course.courses.length}</span> }
                  </div>
                  <div className="dates">
                    <span className="title">Date</span>
                    {hasOnline && <span>Accessible 24/7</span>}
                    {!hasOnline && (
                        <span>
                          {hasDates ? "Multiple dates available" : "Contact for Availability"}
                        </span>
                    )}
                  </div>
                  <div className="link">
                      <Link to={course.uri}>Course Detail</Link>
                  </div>
                </div>
              );
            })}
          </div>
        </>
      }
      <CgbTabSeparatorBlock
          attributes={{
              bottomColor: "white",
              className: "",
              flipTabClass: "",
              topColor: "background"
          }}
          innerBlocks={[]}
      />
      <CgbImageLinkRowBlock
          attributes={{
              className: "white-bg mb-0",
          }}
          innerBlocks={linkBlocks}
      />
    </div>
  )
}
